/* Max width 767px */
@media (max-width: 767px) {
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 20px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pb-130 {
        padding-bottom: 40px;
    }
    h3 {
        font-size: 18px;
    }
    .header-area {
        text-align: center;
        .logo {
            display: none;
        }
        .text-end {
            text-align: center !important;
        }
        .header-content-right {
            .header-contact {
                li {
                    display: inline-block;
                    margin-bottom: 8px;
                }
                &::before {
                    display: none;
                }
            }
            .header-social {
                margin-left: 0;
            }
        }
    }
    .header-content-right {
        float: left;
    }
    .banner-area {
        .container-fluid {
            max-width: 540px;
        }
        .banner-content {
            margin-top: 0;
            text-align: center;
            max-width: unset;
            h1 {
                font-size: 35px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 0;
            }
            .banner-btn {
                margin-top: 25px;
                .box-btn {
                    margin-right: 0;
                    margin: 0 8px;
                }
            }
        }
        .banner-img {
            margin-top: 30px;
        }
    }
    .box-btn {
        padding: 10px 10px;
    }
    p {
        font-size: 14px;
    }
    .banner-area{
        padding-top: 80px;
        padding-bottom: 50px;
        height: 100%;
    }
    .section-title {
        margin-bottom: 30px;
        h2{
            font-size: 25px;
            margin-bottom: 10px;
        }
    }
    .single-service {
        padding: 20px;
        .service-img img {
            width: 50px;
        }
    }
    .home-company-area {
        padding-top: 50px;
        padding-bottom: 50px;
        .company-img {
            margin-top: 30px;
            &::before {
                display: none;
            }
            &::after {
                display: none;
            }
        }
        .company-content {
            .company-tittle {
                h2{
                    font-size: 25px;
                }
            }
            .box-btn {
                margin-top: 30px;
            }
        }
    }
    .choose-img {
        margin-bottom: 30px;
    }
    .choose-area {
        .choose-content {
            .faq-accordion {
                .faq-item {
                    .faq-header {
                        font-size: 15px;
                    }
                    .faq-content {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .home-process-area {
        .single-process {
            .icon {
                margin-bottom: 20px;
            }
            span {
                display: none;
            }
        }
    }
    .home-case {
        .case {
            .all-case {
                margin-bottom: 30px;
                li {
                    font-size: 14px;
                    margin-right: 8px;
                }
            }
        }
        .case-list {
            .single-case {
                .content {
                    padding: 20px;
                }
            }
        }
    }
    .feature-area {
        .feature-tittle {
            h2 {
                font-size: 25px;
            }
        }
    }
    .feature-area {
        .feature-img {
            -webkit-animation: none;
            animation: none;
            margin-top: 30px;
        }
        .contnet ul li {
            font-size: 16px;
            i {
                top: -2px;
            }
        }
    }
    .home-team-area .owl-dots {
        margin-top: 0;
    }
    .single-client{
        padding: 20px;
    }
    .home-blog-area {
        .single-blog  {
            .content {
                padding: 20px;
            }
        }
    }
    .home-blog-area {
        .single-blog {
            .content h3 {
                margin-bottom: 0;
            }
        }
    }
    .home-blog-area {
        .blog-btn {
            margin-top: 0;
        }
    }
    .home-contact-area {
        .contact-img{
            margin-bottom: 30px;
            animation: none;
        }
    }
    .home-contact-area {
        .content{
            padding: 20px;
        }
    }
    .footer-area {
        .content {
            h3 {
                margin-bottom: 10px;
                font-size: 22px;
                &::before{
                    display: none;
                }
                &::after{
                    display: none;
                }
            }
            .footer-list {
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
            .foot-social {
                left: 0;
            }
        }
    }
    .copy-area {
        margin-top: 20px !important;
    }
    .footer-area {
        .copy-area {
            text-align: center;
            .right {
                float: unset;
                margin-top: 10px;
            }
        }
    }
    .slider-area-2 {
        .home-slider {
            .single-slider {
                height: 100%;
                padding-top: 110px;
                padding-bottom: 100px;
                .slider-content {
                    h1 {
                        font-size: 35px;
                        margin-bottom: 10px;
                    }
                }
                .slider-btn .box-btn {
                    margin-right: 0;
                    margin: 0 8px;
                }
            }
        }
        .owl-nav {
            .owl-next {
                width: 40px;
                height: 40px;
                font-size: 15px;
                line-height: 40px;
                top: unset;
                bottom: -30px;
                right: 10%;
                
            }
            .owl-prev {
                width: 40px;
                height: 40px;
                font-size: 15px;
                line-height: 40px;
                top: unset;
                bottom: -30px;
                left: 10%;
                
            }
        }
    }
    .technology-area {
        .technology-content {
            h2 {
                font-size: 25px;
                margin-bottom: 20px;
            }
        }
    }
    .home-case {
        .case {
            .all-case {
                .active {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    .home-2-contact {
        .contact-img {
            height: 300px;
            &.contact-img-2 {
                margin-top: 30px;
                margin-bottom: 0 !important;
            }
        }
    }
    .footer-area-2 {
        .text-right {
            text-align: unset!important;
        }
    }
    .blog-details-area {
        .blog-details-desc {
            .article-content {
                .entry-meta {
                    ul {
                        li {
                            display: block;
                            font-size: 15px;
                            margin-right: 0;
                            margin-bottom: 5px;
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
                h3 {
                    line-height: 1.5;
                    margin-bottom: 10px;
                    margin-top: 20px;
                    font-size: 22px;
                }
                p {
                    font-size: 15px;
                }
                .features-list {
                    li {
                        font-size: 15px;
                    }
                }
            }
        }
        .widget-area .widget {
            padding: 0;
        }
        blockquote {
            padding: 30px !important;
            p {
                font-size: 16px !important;
            }
        }
        .blockquote {
            padding: 30px !important;
            p {
                font-size: 16px !important;
            }
        }
        .comments-area {
            padding: 20px;
            .comment-author {
                .avatar {
                    left: 0;
                    position: relative;
                    display: block;
                    margin-bottom: 10px;
                }
                font-size: 15px;
                margin-bottom: 0.3em;
            }
            .comment-body {
                padding-left: 0;
            }
            .comments-title {
                font-size: 19px;
                &::before {
                    left: -20px;
                }
            }
            .comment-metadata {
                font-size: 12px;
            }
            .comment-respond {
                .comment-reply-title {
                    font-size: 19px;
                    &::before {
                        left: -20px;
                    }
                }
                .comment-form-author {
                    width: 100%;
                    padding-right: 0;
                }
                .comment-form-email {
                    width: 100%;
                    padding-left: 0;
                }
                .form-submit {
                    input {
                        padding: 12px 25px 10px;
                    }
                }
            }
        }
    }
    .blog-details-area .comments-area .children {
        margin-left: 0;
    }
    #secondary {
        margin-top: 35px;
    }
    .page-title-area {
        padding-top: 150px;
        padding-bottom: 100px;
        .page-title-content {
            h2 {
                font-size: 35px;
                margin-bottom: 0;
            }
        }
    }
    .page-navigation-area {
        margin: 0px auto 0;
    }
    .services-details-area {
        .services-img {
           img {
               width: 100%;
           }
        }
        .services-img.mb-qc {
            margin-bottom: 30px;
        }
    }
    .services-details-area .scrives-item-3 .share {
        float: left;
        margin-top: 20px;
    }
    .services-details-area .services-details-text h2 {
        font-size: 25px;
    }
    .tab.quote-list-tab .tabs li{
        font-size: 15px;
    }
    .signup-area .signup-form .box-btn {
        margin-top: 0;
        margin-bottom: 10px;
        width: 100%;
    }
    .signup-area .signup-form{
        padding: 20px;
    }
    .main-banner-three {
        .banner-image-slider {
            .banner-image {
                height: 400px;
            }
        }
        .container-fluid {
            max-width: 540px;
            .col-lg-6.pr-0 {
                padding-right: 15px !important;
            }
        }
        .main-banner-content {
            text-align: center;
            .content {
                max-width: unset;
                margin-top: 110px;
                margin-bottom: 50px;
                h1 {
                    font-size: 30px;
                    margin-bottom: 15px;
                }
                p {
                    margin-bottom: 30px;
                }
            }
        }
    }
    .header-2 .header-content-right .header-social li a {
        font-size: 16px;
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
    .header-area {
        .header-content-right {
            float: unset;
        }
        .col-lg-8.col-sm-8.text-end.pr-0 {
            text-align: center !important;
            padding-right: 15px !important;
        }
        .col-lg-2.col-sm-4.text-end.pl-0 {
            text-align: center !important;
        }
    }
    .header-area.header-2 {
        .header-content-right {
            float: unset;
        }
        .col-lg-8.col-md-7.text-left {
            text-align: center !important;
            padding-right: 15px !important;
            margin-bottom: 5px;
        }
        .col-lg-4.col-md-5.text-right {
            text-align: center !important;
        }
        .header-content-right .header-social li {
            padding-left: 0;
            padding-right: 0;
            margin: 0 4px;
        }
    }
    
}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-area {
        .header-content-right {
            .header-contact {
                li {
                    display: inline-block;
                    margin-right: 15px;
                    margin-bottom: 0;
                }
            }
            float: unset;
        }
        .text-right {
            text-align: unset!important;
        }
    }
    .home-case {
        .case {
            .all-case {
                li {
                    font-size: 16px;
                    margin-right: 40px;
                }
            }
        }
    }
    .home-case {
        .case-list {
            .single-case {
                .content {
                    padding: 15px;
                    h3 {
                        font-size: 16px;
                    }
                    .line-bnt {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    .line-bnt {
        font-size: 13px;
    }
    .blog-details-area  {
        .blog-details-desc {
            .article-content {
                .entry-meta {
                    ul {
                        li {
                            display: inline-block;
                            font-size: 15px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

/* Min width 768px to Max width 991px */
@media (min-width: 768px) and (max-width: 991px) {
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 20px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pb-130 {
        padding-bottom: 40px;
    }
    .header-area {
        text-align: center;
        .logo {
            display: none;
        }
        .header-content-right {
            .header-contact {
                li {
                    display: block;
                    margin-right: 0;
                    margin-bottom: 8px;
                }
                &::before {
                    display: none;
                }
            }
            .header-social {
                margin-left: 0;
            }
        }
    }
    .header-content-right {
        float: left;
    }
    .header-area .header-content-right .header-contact li:last-child {
        margin-right: -11px;
    }
    .header-area .text-end {
        text-align: center !important;
    }
    .banner-area {
        .container-fluid {
            max-width: 720px;
        }
        .banner-content {
            margin-top: 0;
            text-align: center;
            max-width: unset;
            h1 {
                font-size: 40px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 0;
            }
            .banner-btn {
                margin-top: 25px;
                .box-btn {
                    margin-right: 0;
                    margin: 0 8px;
                }
            }
        }
        .banner-img {
            margin-top: 30px;
        }
    }
    .box-btn {
        padding: 10px 10px;
    }
    .banner-area{
        padding-top: 130px;
        padding-bottom: 70px;
        height: 100%;
    }
    .section-title {
        margin-bottom: 30px;
        h2{
            font-size: 30px;
            margin-bottom: 10px;
        }
    }
    .single-service {
        padding: 20px;
    }
    .home-company-area {
        padding-top: 50px;
        padding-bottom: 50px;
        .company-img {
            margin-top: 30px;
            img {
                width: 100%;
            }
            &::before {
                display: none;
            }
            &::after {
                display: none;
            }
        }
        .company-content {
            .company-tittle {
                h2{
                    font-size: 25px;
                }
            }
            .box-btn {
                margin-top: 30px;
            }
        }
    }
    .choose-img {
        margin-bottom: 30px;
    }
    .choose-area {
        .choose-content {
            .faq-accordion {
                .faq-item {
                    .faq-header {
                        font-size: 15px;
                    }
                    .faq-content {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .home-process-area {
        .single-process {
            .icon {
                margin-bottom: 20px;
            }
            span {
                display: none;
            }
        }
    }
    .home-case {
        .case {
            .all-case {
                margin-bottom: 30px;
                li {
                    font-size: 14px;
                    margin-right: 8px;
                }
            }
        }
        .case-list {
            .single-case {
                .content {
                    padding: 20px;
                }
            }
        }
    }
    .feature-area {
        .feature-tittle {
            h2 {
                font-size: 25px;
            }
        }
    }
    .feature-area {
        .feature-img {
            -webkit-animation: none;
            animation: none;
            margin-top: 30px;
            text-align: center;
        }
        .contnet ul li {
            font-size: 16px;
            i {
                top: -2px;
            }
        }
    }
    .home-team-area .owl-dots {
        margin-top: 0;
    }
    .single-client{
        padding: 20px;
    }
    .home-blog-area {
        .single-blog  {
            .content {
                padding: 20px;
            }
        }
    }
    .home-blog-area {
        .single-blog {
            .content h3 {
                margin-bottom: 0;
            }
        }
    }
    .home-blog-area {
        .blog-btn {
            margin-top: 0;
        }
    }
    .home-contact-area {
        .contact-img{
            margin-bottom: 30px;
            animation: none;
        }
    }
    .home-contact-area {
        .content{
            padding: 20px;
        }
    }
    .footer-area {
        .content {
            h3 {
                margin-bottom: 10px;
                font-size: 22px;
                &::before{
                    display: none;
                }
                &::after{
                    display: none;
                }
            }
            .footer-list {
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
            .foot-social {
                left: 0;
            }
        }
    }
    .copy-area {
        margin-top: 20px !important;
    }
    .footer-area {
        .copy-area {
            text-align: center;
            .right {
                float: unset;
                margin-top: 10px;
            }
        }
    }
    .header-area {
        .header-content-right {
            .header-contact {
                li {
                    display: inline-block;
                    margin-right: 15px;
                    margin-bottom: 0;
                }
            }
            float: unset;
        }
        .text-right {
            text-align: unset!important;
        }
    }
    .slider-area-2 {
        .home-slider {
            .single-slider {
                height: 100%;
                padding-top: 150px;
                padding-bottom: 100px;
                .slider-content {
                    h1 {
                        font-size: 35px;
                        margin-bottom: 10px;
                    }
                    p {
                        margin-top: 30px;
                    }
                }
                .slider-btn .box-btn {
                    margin-right: 0;
                    margin: 0 8px;
                }
            }
        }
        .owl-nav {
            .owl-next {
                width: 40px;
                height: 40px;
                font-size: 15px;
                line-height: 40px;
                transform: translateY(0);
            }
            .owl-prev {
                width: 40px;
                height: 40px;
                font-size: 15px;
                line-height: 40px;
                transform: translateY(0);
            }
        }
    }
    .technology-area {
        .technology-content {
            h2 {
                font-size: 25px;
                margin-bottom: 20px;
            }
        }
    }
    .home-case {
        .case {
            .all-case {
                .active {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    .home-2-contact {
        .contact-img {
            height: 300px;
            &.contact-img-2 {
                margin-top: 30px;
                margin-bottom: 0 !important;
            }
        }
    }
    .footer-area-2 {
        .text-right {
            text-align: unset!important;
        }
    }
    .home-2-contact .contact-img {
        height: 100%;
        margin-top: 0 !important;
    }
    .home-case .case .all-case li {
        font-size: 14px;
        margin-right: 40px;
    }
    .header-area .text-right {
        text-align: right!important;
    }
    #secondary {
        margin-top: 35px;
    }
    .page-title-area {
        padding-top: 150px;
        padding-bottom: 100px;
        .page-title-content {
            h2 {
                font-size: 35px;
                margin-bottom: 0;
            }
        }
    }
    .page-navigation-area {
        margin: 0px auto 0;
    }
    .services-details-area {
        .services-img {
           img {
               width: 100%;
           }
        }
        .services-img.mb-qc {
            margin-bottom: 30px;
        }
    }
    .services-details-area .services-details-text h2 {
        font-size: 30px;
    }
    .tab.quote-list-tab .tabs li{
        font-size: 15px;
    }
    .signup-area .signup-form{
        padding: 20px;
    }
    .main-banner-three {
        .banner-image-slider {
            .banner-image {
                height: 500px;
            }
        }
        .container-fluid {
            max-width: 720px;
            .col-lg-6.pr-0 {
                padding-right: 15px !important;
            }
        }
        .main-banner-content {
            text-align: center;
            .content {
                max-width: unset;
                margin-top: 130px;
                margin-bottom: 60px;
                h1 {
                    font-size: 35px;
                }
            }
        }
    }
}

/* Min width 992px to Max width 1200px */
@media (min-width: 992px) and (max-width: 1200px) {
    .header-area {
        .header-content-right {
            .header-contact {
                &::before {
                    right: -25px;
                }
            }
        }
    }
    .navbar-area {
        .main-nav {
            .navbar {
                .navbar-nav {
                    .nav-item {
                        a {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        .box-btn {
            font-size: 14px;
            padding: 15px 6px;
        }
    }
    .banner-area {
        .banner-content {
            max-width: 450px;
            h1 {
                font-size: 48px;
            }
        }
    }
    .single-service {
        padding: 20px;
    }
    .home-company-area {
        padding-top: 100px;
        padding-bottom: 100px;
        .company-img {
            &::before{
                left: unset;
                right: -30px;
            }
            &::after{
                left: -30px;
                right: unset;
            }
        }
    }
    .section-title {
        h2 {
            font-size: 35px;
        }
    }
    .home-company-area {
        .company-content {
            .company-tittle {
                h2 {
                    font-size: 35px;
                }
            }
        }
    }
    .home-case {
        .case-list {
            .single-case {
                .content{
                    padding: 20px;
                    h3 {
                        font-size: 19px;
                    }
                }
            }
        }
    }
    .feature-area {
        .feature-tittle {
            h2 {
                font-size: 35px;
            }
        }
    }
    .home-team-area {
        .owl-dots {
            margin-top: 0;
        }
    }
    .home-blog-area {
        .single-blog {
            .content {
                padding: 19px;
                h3 {
                    font-size: 19px;
                }
            }
        }
    }
    .footer-area {
        .copy-area {
            .menu {
                li{
                    margin-right: 20px;
                    font-size: 14px;
                }
            }
        }
    }
    .footer-area {
        .copy-area {
            p {
                font-size: 14px;
            }
        }
    }
    .slider-area-2 {
        .home-slider {
            .single-slider {
                height: 100%;
                padding-top: 140px;
                padding-bottom: 100px;
            }
        }
    }
    .home2-choose-area {
        .single-choose {
            h3{
                font-size: 19px;
            }
        }
    }
    .main-banner-three {
        .main-banner-content {
            .content {
                max-width: 450px;
                h1 {
                    font-size: 50px;
                }
            }
        }
    }
}

/* Max width 1800px */
@media only screen and (min-width: 1800px) {
    .banner-area {
        height: 100vh;
        .home-shape {
            .shape2 {
                top: 18%;
                left: 16%;
            }
        }
    }
    .home-team-slider-area {
        .team-right {
            height: 100%;
            .single-team {
                padding-left: 40px;
                .content {
                    p {
                        padding: 0 160px;
                    }
                }
            }
        }
    }
}

/* Max width 991px Only Mobile Devices */
@media only screen and (max-width: 991px) {
    
    .navbar-area {
        border-bottom: 1px solid #242424;

        .main-nav {
            position: relative !important;
            top: 0 !important;
            background-color: var(--darkBlueColor);

            .navbar {
                position: relative;
    
                .collapse {
                    margin-top: 15px;
                    max-height: 70vh;
                    overflow-y: scroll;
                }
                .navbar-nav {
                    .nav-item {
                        .dropdown-menu {
                            position: relative;
                            top: 0;
                            width: 100%;
                            opacity: 1;
                            visibility: visible;
                            margin-top: 18px;
                        }
                    }
                }
            }
        }
        .navbar-brand {
            &.d-none {
                display: inline-block !important;
            }
        }
        .nav-right {
            display: none;
        }
        .nav-btn {
            display: none;
        }
        .navbar-toggler {
            color: var(--whiteColor);
            font-size: inherit;
            box-shadow: unset;
            border: none;
            padding: 0;

            .burger-menu {
                cursor: pointer;

                span {
                    height: 2px;
                    width: 30px;
                    margin: 6px 0;
                    display: block;
                    background: var(--whiteColor);
                }
            }
        }
        .active {
            .navbar-toggler {
                .burger-menu {
                    span {
                        &.top-bar {
                            transform: rotate(45deg);
                            transform-origin: 10% 10%;
                        }
                        &.middle-bar {
                            opacity: 0;
                        }
                        &.bottom-bar {
                            transform: rotate(-45deg);
                            transform-origin: 10% 90%;
                            margin-top: 5px;
                        }
                    }
                }
            }
            .collapse:not(.show) {
                display: block;
            }
        }
    }

}